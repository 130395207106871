import React from "react"
import ReactWOW from "react-wow"
import { Link } from "gatsby"
import { Button } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"

import WhereToStart from "../../assets/images/home-loans/property-refinance/icon-rocket.svg"
import FAQ from "../../assets/images/home-loans/icon-FAQ.svg"
import ChevronRight from "../../assets/images/next.png"
import PropertyRefinance from "../../assets/images/home-loans/property-refinance/icon-refinance.svg"

const Banner = () => {
  return (
    <div className="homeloans-hero-banner">
      <div className="main-banner-wrapper property-refinancing-color">
        {/* <hr></hr> */}
        <div className="row">
          <div className="col-12 col-md-5 col-xl-5">
            <div className="main-banner-content property-refinancing-color mb-md-5">
              <img
                className="mb-3"
                src={PropertyRefinance}
                alt="icon-refiance"
              />
              <h1>Home Loan Refinancing Guide</h1>
              <h2 className="property-refinancing-color mt-2">
                Our guide on what to consider when it comes to refinancing.
              </h2>
              <ReactWOW delay=".05s" animation="fadeInUp">
                <div className="">
                  <a
                    className="default-btn primary-btn btn-lg mt-3 mt-md-4"
                    href="https://oxygen-home-loans.s3.ap-southeast-2.amazonaws.com/web-site/guides/Oxygen+Home+Loans+-+Looking+to+Refinance+guide.pdf"
                    target="_blank"
                  >
                    Download Guide Now
                  </a>
                </div>
                <div className="">
                  {`${process.env.GATSBY_ENVIRONMENT}` === "staging" ? (
                    <a
                      href={`${process.env.GATSBY_BACKEND_API_URL}/createSessionOpen/1d9971ea-fc77-4dcc-9fd4-d7d978eaf030`}
                      className="default-btn colored-outline primary-btn property-refinancing-color btn-lg mt-3 mt-lg-3 mb-2"
                      target="_blank"
                    >
                      Apply Now<span></span>
                    </a>
                  ) : (
                    <a
                      href={`${process.env.GATSBY_BACKEND_API_URL}/createSessionOpen/1d9971ea-fc77-4dcc-9fd4-d7d978eaf030`}
                      className="default-btn colored-outline primary-btn property-refinancing-color btn-lg mt-3 mt-lg-3 mb-2"
                      target="_blank"
                    >
                      Apply Now<span></span>
                    </a>
                  )}
                </div>
              </ReactWOW>
            </div>
          </div>
          <div className="col-12 col-md-7 col-xl-7 d-flex align-items-center">
            <div className="container mt-4">
              {/* <div className="col-12">
              <p>Here's what we can do for you:</p>
            </div> */}
              <div className="row ml-lg-5 pt-5 pt-md-0">
                <div className="col-12">
                  <div
                    className="summary-container d-flex justify-content-start mb-md-5 "
                    onClick={() => scrollTo("#get-started", "start")}
                  >
                    <img
                      className="mr-3"
                      src={WhereToStart}
                      alt="icon-rocket"
                    />
                    <div className="flex-column-justify-center">
                      <h4>Where to start?</h4>
                      <h5 className="why-item-text">
                        We can help you weigh it all up.
                      </h5>
                    </div>
                    <img
                      className="chevron-right"
                      src={ChevronRight}
                      alt="chevron right"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div
                    className="summary-container d-flex justify-content-start"
                    onClick={() => scrollTo("#faq", "start")}
                  >
                    <img className="mr-3" src={FAQ} alt="icon-FAQ" />
                    <div className="flex-column-justify-center">
                      <h4>FAQs</h4>
                      <h5 className="why-item-text">Check our FAQs</h5>
                    </div>
                    <img
                      className="chevron-right"
                      src={ChevronRight}
                      alt="chevron right"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
