import React from "react"
import ReactWOW from "react-wow"
import { Link } from "gatsby"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/RefinanceHomeLoan/Banner.js"
import Footer from "../components/App/Footer"
import CTABanner from "../components/Common/CTABanner"
import SEO from "../components/App/SEO"

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion"

const RefinancingHomeLoan = () => {
  // Responsive rendering
  // Responsive rendering
  // const [width, setWidth] = React.useState(1199)

  // useEffect(() => {
  //   if (typeof window !== `undefined` && width ==undefined) {

  //     // const updateWidthAndHeight = () => {
  //       setWidth(1000)

  //   // }
  //   console.log("width", window.innerWidthth);
  // }
  // });

  return (
    <Layout>
      <SEO
        title="Home Loan Refinancing | Oxygen - Home Loans Made Simple"
        description="At Oxygen we have a very simple value proposition. Provide our customers with a higher quality of advice, the most competitive loan, and do it hassle free."
      />
      <Navbar />
      <PageBanner />

      {/* PropertyRefinance */}
      <section id="get-started">
        <div className="light-green-section-wrapper pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-12 pt-5 pb-5">
                <h3 className="h3-careers">Where to start?</h3>
                <p>
                  As time marches on, situations change. Perhaps you’ve changed
                  jobs? Or there’s a new addition to the family? Maybe you would
                  just like a better rate? Maybe it’s the advent of school fees,
                  or perhaps the kids have flown the coop? Or maybe that leaking
                  shower or tired kitchen has just reached the end of its life.
                </p>
                <p>
                  A shift in circumstances may mean it is time to revisit your
                  home finances. For many, the idea of refinancing a mortgage
                  can be daunting. Fees and fixed versus variable interest rates
                  need to be considered.
                </p>
                <p>
                  The right refinanced loan might help you to pay off your
                  mortgage faster and for less, clear unhealthy debt or upgrade
                  and add value your home, all of which are steps in the right
                  direction.
                </p>
              </div>
            </div>
            <ReactWOW delay=".05s" animation="fadeInUp">
              <div className="row d-flex justify-content-center pb-5">
                <div className="col-12 col-md-5 col-lg-4">
                  <a
                    className="default-btn navy-btn-solid btn-lg mt-2 mt-md-0"
                    href="https://oxygen-home-loans.s3.ap-southeast-2.amazonaws.com/web-site/guides/Oxygen+Home+Loans+-+Looking+to+Refinance+guide.pdf"
                    target="_blank"
                  >
                    Download Guide Now
                  </a>
                </div>
                <div className="col-12 col-md-5 col-lg-4">
                  {`${process.env.GATSBY_ENVIRONMENT}` === "staging" ? (
                    <a
                      href={`${process.env.GATSBY_BACKEND_API_URL}/createSessionOpen/1d9971ea-fc77-4dcc-9fd4-d7d978eaf030`}
                      className="default-btn navy-btn-solid light-green-btn-outline-solid btn-lg mt-3 mt-md-0"
                      target="_blank"
                    >
                      Apply Now<span></span>
                    </a>
                  ) : (
                    <a
                      href={`${process.env.GATSBY_BACKEND_API_URL}/createSessionOpen/1d9971ea-fc77-4dcc-9fd4-d7d978eaf030`}
                      className="default-btn navy-btn-solid light-green-btn-outline-solid btn-lg mt-3 mt-md-0"
                      target="_blank"
                    >
                      Apply Now<span></span>
                    </a>
                  )}
                </div>
              </div>
            </ReactWOW>
          </div>
        </div>
      </section>

      {/* FAQ Guide - For those thinking of refinancing */}
      <section id="faq">
        <div className="plain-section-wrapper faq-dropdown-wrapper ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="h3-careers">
                  FAQ Guide - For those thinking of refinancing
                </h3>
                <div className="faq-accordion mt-4 mt-md-5">
                  {/* {width > 1199 ? ( */}
                  <Accordion preExpanded={["a", "b", "c", "d", "e", "f"]}>
                    <AccordionItem uuid="a">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Q1. Can I get a mortgage where I pay less than I’m
                          paying now?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3 mb-3">
                          Now is always a great time to shop around or check
                          that you have the right loan for your needs. We’re a
                          great starting point. It will depend what interest
                          rate you’re currently paying, what type of home loan
                          you have (e.g. fixed, variable, interest only, line of
                          credit) and what features you want in your loan. We
                          can quickly explain your options. We have a powerful{" "}
                          <Link
                            to="/calculators/loan-comparison-calculator"
                            className="underlined-link link"
                          >
                            Loan Comparison Calculator
                          </Link>{" "}
                          that can give you an idea on how much money you can
                          save – or of course – talk to our brokers – they would
                          be glad to help.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid="b">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Q2. Can I consolidate credit card or other debts into
                          a home loan?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3 mb-3">
                          This is one of the reasons some people refinance. The
                          advantage is that you pay a much lower interest rate
                          on a mortgage than for most other forms of debt – e.g.
                          credit cards, overdraft facilities, personal loans
                          etc. Providing you have sufficient equity in your
                          property, you may be able to consolidate all your debt
                          on a home loan. If you take this option though it is
                          important to make sure you maintain your repayments of
                          the debt that you consolidate at their current level,
                          or you could easily end up paying more over a longer
                          period of time. Speak with us today to discuss your
                          personal needs.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid="c">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Q3. How much money can I borrow?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3 mb-3">
                          We’re all unique when it comes to our finances and
                          borrowing needs. Get an estimate on how much you may
                          be able to borrow (subject to satisfying legal and
                          lender requirements) with{" "}
                          <Link
                            to="/calculators/borrowing-power-calculator"
                            className="underlined-link link"
                          >
                            borrowing power calculator
                          </Link>
                          . Chat to us when you’re ready, we can help with
                          calculations based on your circumstances.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid="d">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Q4. How do I choose a loan that’s right for me?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3 mb-3">
                          Our brokers will guide you on what loan types and
                          features are available. There are hundreds of
                          different home loans available so talk to us today.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid="e">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Q5. How often do I make home loan repayments — weekly,
                          fortnightly or monthly?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3 mb-3">
                          Most lenders offer flexible repayment options to suit
                          your pay cycle. Aim for weekly or fortnightly
                          repayments, instead of monthly, as you will make more
                          payments in a year, which will shave dollars and time
                          off your loan.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem uuid="f">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Q6. What fees/costs are involved in switching
                          mortgages?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="mt-3 mb-3">
                          Depending on your loan, penalty fees could apply if
                          you’re paying off your current mortgage early. But
                          these may be offset by repayment savings when you
                          switch home loans. We’ll walk you through any fees
                          that could apply in your circumstances.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                  {/* ) : (
                    <Accordion allowZeroExpanded>
                      <AccordionItem uuid="a">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Q1. Can I get a mortgage where I pay less than I’m
                            paying now?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3 mb-3">
                            Now is always a great time to shop around or check
                            that you have the right loan for your needs. We’re a
                            great starting point. It will depend what interest
                            rate you’re currently paying, what type of home loan
                            you have (e.g. fixed, variable, interest only, line
                            of credit) and what features you want in your loan.
                            We can quickly explain your options. We have a
                            powerful{" "}
                            <Link
                              to="/calculators/loan-comparison-calculator"
                              className="underlined-link link"
                            >
                              Loan Comparison Calculator
                            </Link>{" "}
                            that can give you an idea on how much money you can
                            save – or of course – talk to our brokers – they
                            would be glad to help.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem uuid="b">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Q2. Can I consolidate credit card or other debts
                            into a home loan?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3 mb-3">
                            This is one of the reasons some people refinance.
                            The advantage is that you pay a much lower interest
                            rate on a mortgage than for most other forms of debt
                            – e.g. credit cards, overdraft facilities, personal
                            loans etc. Providing you have sufficient equity in
                            your property, you may be able to consolidate all
                            your debt on a home loan. If you take this option
                            though it is important to make sure you maintain
                            your repayments of the debt that you consolidate at
                            their current level, or you could easily end up
                            paying more over a longer period of time. Speak with
                            us today to discuss your personal needs.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem uuid="c">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Q3. How much money can I borrow?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3 mb-3">
                            We’re all unique when it comes to our finances and
                            borrowing needs. Get an estimate on how much you may
                            be able to borrow (subject to satisfying legal and
                            lender requirements) with{" "}
                            <Link
                              to="/calculators/borrowing-power-calculator"
                              className="underlined-link link"
                            >
                              borrowing power calculator
                            </Link>
                            . Chat to us when you’re ready, we can help with
                            calculations based on your circumstances.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem uuid="d">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Q4. How do I choose a loan that’s right for me?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3 mb-3">
                            Our brokers will guide you on what loan types and
                            features are available. There are hundreds of
                            different home loans available so talk to us today.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem uuid="e">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Q5. How often do I make home loan repayments —
                            weekly, fortnightly or monthly?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3 mb-3">
                            Most lenders offer flexible repayment options to
                            suit your pay cycle. Aim for weekly or fortnightly
                            repayments, instead of monthly, as you will make
                            more payments in a year, which will shave dollars
                            and time off your loan.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem uuid="f">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Q6. What fees/costs are involved in switching
                            mortgages?
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="mt-3 mb-3">
                            Depending on your loan, penalty fees could apply if
                            you’re paying off your current mortgage early. But
                            these may be offset by repayment savings when you
                            switch home loans. We’ll walk you through any fees
                            that could apply in your circumstances.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CTABanner />
      <Footer />
    </Layout>
  )
}

export default RefinancingHomeLoan
